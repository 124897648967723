$c1: #0278AE;
$c2: #51ADCF;
$c3: #A5ECD7;
$c4: #E8FFC1;
$c5: #ccc;

$bp-max-359:  "only screen and (max-width: 359px)"; // @media#{$bp-max-359} { ... }
$bp-min-360:  "only screen and (min-width: 360px)"; // @media#{$bp-min-360} { ... }
$bp-max-479:  "only screen and (max-width: 479px)"; // @media#{$bp-max-479} { ... }
$bp-min-480:  "only screen and (min-width: 480px)"; // @media#{$bp-min-480} { ... }
$bp-max-767:  "only screen and (max-width: 767px)"; // @media#{$bp-max-767} { ... }
$bp-min-768:  "only screen and (min-width: 768px)"; // @media#{$bp-min-768} { ... }
$bp-max-1023: "only screen and (max-width:1023px)"; // @media#{$bp-max-1023} { ... }
$bp-min-1024: "only screen and (min-width:1024px)"; // @media#{$bp-min-1024} { ... }
$bp-max-1279: "only screen and (max-width:1279px)"; // @media#{$bp-max-1279} { ... }
$bp-min-1280: "only screen and (min-width:1280px)"; // @media#{$bp-min-1280} { ... }
$bp-max-1439: "only screen and (max-width:1439px)"; // @media#{$bp-max-1439} { ... }
$bp-min-1440: "only screen and (min-width:1440px)"; // @media#{$bp-min-1440} { ... }
$bp-min-1610: "only screen and (min-width:1610px)"; // @media#{$bp-min-1610} { ... }

html,body {
  height:100%;
}

body {
  background:$c1;
  margin:0;
  padding:20px;
  @media#{$bp-min-1024} {

    &:not(.con-spiegone) {
      display:flex;
      justify-content: center;
      align-items: center;
    }
  }
}
* {
  box-sizing:border-box;
  margin:0;
}

.test-container {
  background: $c4;
  border:10px solid $c3;
  padding:2em;
  border-radius:20px;
  font: 1.2em/1.6 sans-serif;
  color: $c1;
  max-width:500px;
  html body.con-spiegone & {
    max-width:800px;
    margin-top:80px;
  }
  margin:30px auto 30px auto;
  position:relative;
  padding-top:3em;
  h1 {
    font-size: 1.4em;
    line-height:1.1;
  }
  h2 {
    font-size:1.1em;
    line-height:1.2;
    margin-bottom:1em;
    font-weight:normal;
    margin-top:0.4em;
  }
  p {
    font-size:0.8em;
    line-height:1.2;
    &:not(:last-child) {
      margin-bottom:0.5em;
    }
    &.alternativa {
      text-align:center;
    }
  }
  .spiegone {
    &.nascondi {
      display:none;
    }
  }
  .nascosto {
    display:none;
  }
  @media#{$bp-min-1024} {
    border-radius:0 20px 20px 20px;
    padding-top:2em;
    &:after {
      content:"";
      position:absolute;
      top:0;
      left:0;
      border-width: 15px;
      border-style: solid;
      border-color: $c3 $c3 transparent transparent;
      transform: translateX(-40px) translateY(-10px);
      margin: 0;
    }
    h1 {
      font-size: 1.7em;
      line-height:1.1;
    }
    h2 {
      font-size:1.3em;
      line-height:1.2;
      margin-bottom:1em;
      font-weight:normal;
      margin-top:0.4em;
    }
    p {
      font-size:1em;
      line-height:1.2;


    }
  }
  .avatar {
    position:absolute;
    top:0;
    left:50%;
    width:30%;

    border:10px solid $c2;
    background-image: url("../images/_ric.jpg");
    background-size: cover;
    border-radius: 50%;
    transform: translateX(-50%) translateY(-50%);
    &:after {
      content:"";
      display:block;
      padding-top:100%;
    }
    @media#{$bp-min-1024} {
      width:30%;
      top:0;
      left:0;
      transform: translateX( calc(-100% - 20px ) ) translateY(-70px);
      html body.con-spiegone & {
        width:150px;
      }
    }
  }




  a {
    color:$c2;
    text-decoration:none;
  }


  //a {
  //  color:$c2;
  //  text-decoration:none;
  //  display: inline-block;
  //  position: relative;
  //  padding-left:1.3em;
  //  line-height:1;
  //
  //  &:before {
  //    content:"";
  //    display:block;
  //    position:absolute;
  //    border-width: 0.5em;
  //    border-style: solid;
  //    border-color:  transparent $c1 transparent transparent;
  //    line-height:1;
  //    height:0;
  //    width:0;
  //    left:0;
  //    top:50%;
  //    transform: translateY(-50%) rotate(0deg);
  //    transition:all 0.2s ease-out;
  //  }
  //  &:hover {
  //    &:before {
  //      transform: translateY(-50%) translateX(-50%);
  //      border-color:  transparent $c2 transparent transparent;
  //    }
  //  }
  //}

  input {
    width:100%;
    font-size:1em;
    line-height:2em;
    height:2em;
    display:block;
    margin: 0.5em 0 1em 0;
    color:#999;
    &[type="submit"] {
      display:block;
      background:$c2;
      border:none;
      border-radius:0.5em;
      font-size:1em;
      line-height:3em;
      height:3em;
      color:#fff;
      html.desktop &:hover {
        cursor:pointer;
      }
    }
    &:focus {
      outline: none;
      &:not([type="submit"]) {
        color:#666;
      }
    }
  }


  ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: $c5;
  }
  ::-moz-placeholder { /* Firefox 19+ */
    color: $c5;
  }
  :-ms-input-placeholder { /* IE 10+ */
    color: $c5;
  }
  :-moz-placeholder { /* Firefox 18- */
    color: $c5;
  }





}


